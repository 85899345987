import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo.png";
import { useNavigate } from "react-router-dom";
import docSys from "../Images/docSys.png";
import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import userdata from "../typesofusers.json";

const Login = ({ setLoginInvoke }) => {
  const [accountNumber, setaccNo] = useState("");
  const [password, setpassword] = useState("");
  const [loginInProcess, setLoginInProcess] = useState(false);
  const [did, setdid] = useState("");

  useEffect(() => {
    // Generate fingerprint
    Fingerprint2.get((components) => {
      const deviceId = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
    });
  }, [did]);

  const navigate = useNavigate();
  const handleLogin = async () => {
    const loginButton = document.getElementById("loginButton");
    loginButton.classList.add(classes.btnDisabled);
    console.log("login button clicked", performance.now() / 1000);
    const accno = accountNumber;
    localStorage.setItem("accno", accountNumber);

    if (userdata.form2user && userdata.form2user.includes(accno)) {
      console.log("test", accno, password);
      await axios
        .get("https://iterve.aivot.ai/apollouser1/login", {
          params: { accno, password },
        })
        .then(async (res) => {
          // console.log("login api called", performance.now() / 1000);
          // console.log(res.data);
          if (res.status === 200) {
            localStorage.setItem("accno", accno);
            console.log("inside test login");
            console.log("login user found", res.data);
            await axios
              .put("https://iterve.aivot.ai/apollouser1", {
                params: {
                  accno: accno,
                  deviceId: did,
                },
              })
              .then((res) => setLoginInvoke && setLoginInvoke((val) => !val))
              .catch((error) => {
                console.log(error);
              });
            localStorage.setItem("page", "apollomember");
            navigate("/reports1");
          } else {
            loginButton.classList.remove(classes.btnDisabled);
            window.alert("invalid user");
          }
        })
        .catch((error) => {
          loginButton.classList.remove(classes.btnDisabled);
          console.log(error);
        });
    } else if (userdata.form3user && userdata.form3user.includes(accno)) {
      console.log("palm", accno, password);
      await axios
        .get("https://iterve.aivot.ai/palmuser/login", {
          params: { accno, password },
        })
        .then(async (res) => {
          // console.log("login api called", performance.now() / 1000);
          // console.log(res.data);
          if (res.status === 200) {
            localStorage.setItem("accno", accno);
            console.log("inside test login");
            await axios
              .put("https://iterve.aivot.ai/palmuser", {
                params: {
                  accno: accno,
                  deviceId: did,
                },
              })
              .then((res) => setLoginInvoke && setLoginInvoke((val) => !val))
              .catch((error) => {
                console.log(error);
              });
            localStorage.setItem("page", "palmuser");
            navigate("/reports1");
          } else {
            loginButton.classList.remove(classes.btnDisabled);
            window.alert("invalid user");
          }
        })
        .catch((error) => {
          loginButton.classList.remove(classes.btnDisabled);
          console.log(error);
        });
    } else if (userdata.facePalmUser && userdata.facePalmUser.includes(accno)) {
      console.log("facePalm", accno, password);
      await axios
        .get("https://iterve.aivot.ai/facepalmuser/login", {
          params: { accno, password },
        })
        .then(async (res) => {
          // console.log("login api called", performance.now() / 1000);
          // console.log(res.data);
          if (res.status === 200) {
            localStorage.setItem("accno", accno);
            console.log("inside test login");
            await axios
              .put("https://iterve.aivot.ai/facepalmuser", {
                params: {
                  accno: accno,
                  deviceId: did,
                },
              })
              .then((res) => setLoginInvoke && setLoginInvoke((val) => !val))
              .catch((error) => {
                console.log(error);
              });
            localStorage.setItem("page", "facepalmuser");
            navigate("/reports1");
          } else {
            loginButton.classList.remove(classes.btnDisabled);
            window.alert("invalid user");
          }
        })
        .catch((error) => {
          loginButton.classList.remove(classes.btnDisabled);
          console.log(error);
        });
    } else {
      await axios
        .get("https://iterve.aivot.ai/apollouser/login", {
          params: { accno, password },
        })
        .then(async (res) => {
          // console.log("login api called", performance.now() / 1000);
          // console.log(res.data);
          if (res.status === 200) {
            localStorage.setItem("accno", accno);
            await axios
              .put("https://iterve.aivot.ai/apollouser", {
                params: {
                  accno: accno,
                  deviceId: did,
                },
              })
              .then((res) => setLoginInvoke && setLoginInvoke((val) => !val))
              .catch((error) => {
                console.log(error);
              });
            localStorage.setItem("page", "apollomember");
            navigate("/reports");
          } else {
            loginButton.classList.remove(classes.btnDisabled);
            window.alert("invalid user");
          }
        })
        .catch((error) => {
          loginButton.classList.remove(classes.btnDisabled);
          console.log(error);
        });
    }
    loginButton.classList.remove(classes.btnDisabled);
  };
  return (
    <div className={classes.container}>
      <div className={`${classes.verticalHalfPage} ${classes.leftPartOfPage}`}>
        <div className={classes.logoRow}></div>
        <div className={classes.midRow}>
          <img src={docSys} alt="docSys img here"></img>
        </div>
        <div className={classes.lastRow}>
          <div className={classes.content}>
            <div className={classes.headText}>
              Welcome to Iterve
              <br />
              Health Check
            </div>
            <div className={classes.explText}>Powered by Aivot AI Pvt Ltd</div>
          </div>
        </div>
      </div>
      <div className={`${classes.verticalHalfPage} ${classes.rightPartOfPage}`}>
        <div className={classes.logoRow}>
          <img src={aivotLogo} alt="aivotLogo" className={classes.aivotLogo} />
        </div>
        <div className={classes.midRow}>
          <div className={classes.form}>
            <div className={classes.headText}>Log in</div>
            <div className={classes.formTextLabel}>Username</div>
            <input
              className={classes.formTextInput}
              placeholder="Enter your Username"
              value={accountNumber}
              onChange={(e) => {
                setaccNo(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            <div className={classes.formTextLabel}>Password</div>
            <input
              className={classes.formTextInput}
              placeholder="Enter your password"
              type="password"
              value={password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <div className={classes.lastRow}>
          <div className={classes.btnWrapper}>
            <button
              className={classes.btn}
              onClick={handleLogin}
              id="loginButton"
            >
              Log In
            </button>
            <button className={classes.btn} onClick={() => navigate("/")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
