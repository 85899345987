import React, { useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import MyDocument from "./myPDF.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import userdata from "../typesofusers.json";

function Pdf() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const healthParameters = state.healthParameters;
  const formData = state.formData;
  const accno = state.accountNumber;
  const pageDataArray = state.pageDataArray;

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const generatePdf = async () => {
        const document = (
          <MyDocument
            healthParameters={healthParameters}
            formData={formData}
            accno={accno}
            pageDataArray={pageDataArray}
          />
        );
        console.log("doc oc", document);
        const blob = await pdf(document).toBlob();
        const url = URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        console.log("name", formData["name"]);
        const rawName = formData["name"] || "Report";
        const sanitizedFileName = rawName.replace(/[^a-zA-Z0-9_-]/g, ""); // Remove invalid characters
        console.log("Sanitized Name:", sanitizedFileName);

        // Set the file name
        link.download = `${sanitizedFileName}_report.pdf`;
        link.click();
        URL.revokeObjectURL(url);
      };

      generatePdf();
      if (userdata.form2user && userdata.form2user.includes(accno)) {
        navigate(-1);
      } else {
        navigate(-1);
      }
    }
  }, [healthParameters, formData, accno, pageDataArray]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        margin: 0,
      }}
    >
      <p>Generating your report, please wait...</p>
    </div>
  );
}

export default Pdf;
