import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./WebcamRecorder.module.css";
import extractRGBs from "./selftestUtil.js";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import { FaceMesh } from "@mediapipe/face_mesh";
import { updateInput } from "./formUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function uploadDataToDatabase(vitalsData, rgbd, navigate) {
  const accno = localStorage.getItem("accno");
  const page = localStorage.getItem("page");

  console.log(accno, page);

  if (page === "facepalmuser") {
    console.log("face palm backend", vitalsData);
    axios
      .post("https://iterve.aivot.ai/facepalmuser", vitalsData, {
        params: { accno },
      })
      .then((response) => {
        const rgbid = response.data;
        console.log(rgbid);
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            if (accno === "Hiims_data" || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else if (accno === "Demo_1") {
              navigate("/hrvbodycardvitals", { state: vitalsData });
            } else if (accno === "Demo_2" || accno === "deepak") {
              navigate("/hrvbodycardhemovitals", { state: vitalsData });
            } else if (accno === "DemoAyur1" || accno === "DemoAyur2") {
              navigate("/reportsfacepalm", { state: vitalsData });
            } else {
              navigate("/doshavitals", { state: vitalsData });
            }
          });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  } else {
    console.log("end user", vitalsData);
    axios
      .post("https://iterve.aivot.ai/enduser", vitalsData)
      .then(async (response) => {
        console.log(response.data);

        const rgbid = response.data._id;
        const accno = response.data.name;
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            if (accno === "Hiims_data" || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else {
              navigate("/doshavitals", { state: vitalsData });
            }
          });
      })
      .catch((error) => {
        console.log();
      });
  }
}

const generateCustomId = () => {
  const uuid = uuidv4();
  const letters = uuid
    .replace(/[^a-zA-Z]/g, "")
    .slice(0, 2)
    .toUpperCase(); // Get first two letters and convert to uppercase
  const digits = uuid.replace(/\D/g, "").slice(0, 4); // Remove non-digits and get the first 4 digits
  return `${letters}${digits}`;
};

function WebcamRecorder(props) {
  const interval = 500;
  const navigate = useNavigate();

  const accno = localStorage.getItem("accno");
  const page = localStorage.getItem("page");

  const maxTime = 60 * 1000;
  const fps = 30;

  const {
    startRecording,
    setRecording,
    setLoading,
    isChecked,
    userform,
    alertWDC,
    isAlertActive,
    setAlertActive,
  } = props;
  const formData = new FormData();

  formData.append("name", userform && userform.name);
  formData.append("email", userform && userform.email);
  formData.append("phoneNumber", userform && userform.phoneNumber);
  formData.append("age", userform && userform.age);
  formData.append("weight", userform && userform.weight);
  formData.append("height", userform && userform.height);
  formData.append("gender", userform && userform.gender);
  formData.append("Diabetic", userform && userform.diabetic);
  formData.append("Activity_Factor", userform && userform.activityFactor);
  formData.append("vatavalue", userform && userform.vatavalue);
  formData.append("pittavalue", userform && userform.pittavalue);
  formData.append("kaphavalue", userform && userform.kaphavalue);
  formData.append("vatabalance", userform && userform.vatabalance);
  formData.append("pittabalance", userform && userform.pittabalance);
  formData.append("kaphabalance", userform && userform.kaphabalance);
  formData.append("doshaCombination", userform && userform.doshaCombination);

  const webcamRef = useRef();
  const [progress, setProgress] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [model, setModel] = useState(null);
  const [detectionInProgress, setDetectionInProgress] = useState(false);
  const [senddata, setsenddata] = useState({});
  const [detectionProcessCount, setDetectionProcessCount] = useState(0);

  useEffect(() => {
    const model_setter = async () => {
      // const detector_baseModel = (await axios.get("https://iterve.aivot.ai/model/")).data;
      // console.log(detector_baseModel);
      const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      console.log(model);
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
        // detectorModelUrl: `${process.env.PUBLIC_URL}/face_detector/model.json`,
        // detectorModelUrl: "https://iterve.aivot.ai/model/model.json",
      };
      const detector = await faceLandmarksDetection.createDetector(
        model,
        detectorConfig
      );
      console.log(detector);
      setModel(detector);
    };
    model_setter();
  }, []);

  useEffect(() => {
    if (detectionInProgress) {
      console.log("called through useEffect");
      console.log("loading starts here", performance.now());
      const ss = webcamRef.current.getScreenshot({ width: 320, height: 240 });

      const loadImage = (src) =>
        new Promise((imgResolve) => {
          const img = new Image();
          img.onload = () => {
            imgResolve(img);
          };
          img.src = src;
        });

      loadImage(ss)
        .then((img) => model.estimateFaces(img))
        .then(console.log("loading resolves here", performance.now()))
        .then(
          setTimeout(() => {
            setLoading(false);
            document
              .getElementById("webcam-container")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 1000)
        )
        .then(
          extractRGBs(
            webcamRef,
            fps,
            model,
            detectionInProgress,
            setProgress,
            setDetectionProcessCount
          ).then((res) => {
            setProgress(0);
            setRecording(false);
            const rgbDatas = res[0];
            const fps = res[1];

            if (!rgbDatas) {
              setDetectionInProgress(false);
              setAlertActive(true);
              window.alert(
                "Make sure that your face is in front of the camera"
              );
              setTimeout(() => setAlertActive(false), 100);
              return;
            }

            const apiInputObject = {};

            // Iterate over apiInput (assuming it already has some key-value pairs)
            for (const [key, value] of formData.entries()) {
              apiInputObject[key] = value;
            }
            apiInputObject["subject_id"] = generateCustomId();
            apiInputObject.fps = fps;
            apiInputObject.red = [];
            apiInputObject.green = [];
            apiInputObject.blue = [];

            if (rgbDatas === true) return; // detection is not in progress

            // chANGE 1350 x3 to 3x1350
            const customId = generateCustomId();

            for (let i = 0; i < 1350; i++) {
              apiInputObject.red.push(rgbDatas[i][0]);
              apiInputObject.green.push(rgbDatas[i][1]);
              apiInputObject.blue.push(rgbDatas[i][2]);
            }
            console.log(apiInputObject);

            // Send the combined data as JSON
            fetch("https://test.iterve.ai/api_a/process_data", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(apiInputObject),
            })
              .then((res) => {
                if (res.status !== 200) {
                  throw new Error(
                    `Request failed with status ${res.status}. ${res.json()}`
                  );
                }
                return res.json();
              }) // If expecting JSON response
              .then((response_data) => {
                console.log(response_data);
                const accno = localStorage.getItem("accno");
                const vitalsData = {
                  name: apiInputObject.name,
                  email: apiInputObject.email,
                  subjectid: apiInputObject["subject_id"],
                  phoneNumber: apiInputObject.phoneNumber,
                  diabetic: apiInputObject.Diabetic,
                  activityFactor: apiInputObject.Activity_Factor,
                  age: apiInputObject.age,
                  gender: apiInputObject.gender == 1 ? "Male" : "Female",
                  weight: apiInputObject.weight,
                  height: apiInputObject.height,
                  allowable_blood_loss: response_data["Average Blood Loss"],
                  bmi: response_data["bmi"],
                  body_fat: response_data["bfp"],
                  body_water: response_data["tbw"],
                  breathing_rate: response_data["Breathing Rate"],
                  cardiac_index: response_data["Cardiac Index"],
                  cardiac_output: response_data["Cardiac Output"],
                  dbp: response_data["DBP"],
                  hdl: response_data["HDL"],
                  hba1c: response_data["HBA1C"],
                  heart_rate: response_data["HR"],
                  hematocrit: response_data["Haematocrit"],
                  hemoglobin: response_data["Haemoglobin"],
                  hemoglobin_palm: response_data["Haemoglobin_palm"],
                  ibi: response_data["Mean IBI"],
                  ldl: response_data["LDL"],
                  mean_atrial_pressure: response_data["Mean Arterial Pressure"],
                  mean_corpuscular_hemoglobin: response_data["MCHC"],
                  mean_corpuscular_volume: response_data["MCV"],
                  mean_plasma_glucose: response_data["ABG"],
                  oxygen_saturation: response_data["SpO2"],
                  pulse_pressure: response_data["Pulse Pressure"],
                  pulse_rate_quotient: response_data["Pulse Rate Quotient"],
                  rbc_count: response_data["RBC Count"],
                  rmssd: response_data["RMSSD"],
                  random_blood_sugar: response_data["random_blood_sugar"],
                  random_blood_sugar_palm:
                    response_data["random_blood_sugar_palm"],
                  sbp: response_data["SBP"],
                  sd1: response_data["SD1"],
                  sd2: response_data["SD2"],
                  sdnn: response_data["SDNN"],
                  stress_index: response_data["Stress Index Bravesky"],
                  stroke_volume: response_data["Stroke Volume"],
                  subcutaneous_fat: response_data["sf"],
                  total_cholestrol: response_data["Total_Cholesterol"],
                  triglycerides: response_data["Triglycerides"],
                  vldl: response_data["VLDL"],
                  visceral_fat: response_data["vf"],
                  Amid: response_data["AMID"],
                  Amp: response_data["Amplitude"],
                  BSA: response_data["BSA"],
                  bloodVolume: response_data["Blood Volume"],
                  // New parameters
                  DMID: response_data["DMID"],
                  fattyLiverIndex: response_data["Fatty liver Index"],
                  GGPP: response_data["GGPP"],
                  GGPP_old: response_data["GGPP_old"],
                  HF_power: response_data["HF power"],
                  Hip: response_data["Hip"],
                  Hip_Waist_Ratio: response_data["Hip_Waist_Ratio"],
                  K_Value: response_data["K-Value"],
                  Kurtosis: response_data["Kurtosis"],
                  LDL_old: response_data["LDL_old"],
                  LF_HF_Ratio: response_data["LF HF Ratio"],
                  LF_power: response_data["LF power"],
                  MCH: response_data["MCH"],
                  PNS_Index: response_data["PNS Index"],
                  RBC_Count_Old: response_data["RBC Count_Old"],
                  SDSD: response_data["SDSD"],
                  SGOT: response_data["SGOT"],
                  SGOT_old: response_data["SGOT_old"],
                  SGPT: response_data["SGPT"],
                  SGPT_old: response_data["SGPT_old"],
                  SNS_Index: response_data["SNS Index"],
                  Skewness: response_data["Skewness"],
                  Spectral_Energy: response_data["Spectral Energy"],
                  Stress_Index_old: response_data["Stress Index Bravesky_old"],
                  Stress_Index_us: response_data["Stress Index us"],
                  Triglycerides_old: response_data["Triglycerides_old"],
                  Waist: response_data["Waist"],
                  age_sex_factor: response_data["age_sex_factor"],
                  bfm: response_data["bfm"],
                  bmr: response_data["bmr"],
                  dcn: response_data["dcn"],
                  ecgplot: response_data["ecgplot"],
                  lbm: response_data["lbm"],
                  mNPV: response_data["mNPV"],
                  param1: response_data["param1"],
                  param2: response_data["param2"],
                  serumCreatinine: response_data["serum_creatine"],
                  uricAcid: response_data["uric_acid"],
                  vata: formData.get("vatavalue"),
                  pitta: formData.get("pittavalue"),
                  kapha: formData.get("kaphavalue"),
                  vatabalance: formData.get("vatabalance"),
                  pittabalance: formData.get("pittabalance"),
                  kaphabalance: formData.get("kaphabalance"),
                  doshaCombination: formData.get("doshaCombination"),
                };
                console.log("vitalsData", vitalsData);
                // axios
                //   .post("https://iterve.aivot.ai/palmuser", vitalsData, {
                //     params: { accno },
                //   })
                //   .then((res) => {
                //     console.log(res);
                //   });
                // navigate("/reportsfacepalm", { state: vitalsData });
                uploadDataToDatabase(
                  vitalsData,
                  JSON.stringify(rgbDatas),
                  navigate
                );
              })
              .catch((error) => {
                console.log("API ERROR", error);
                alertWDC("Some noise detected, please retest");
              });
          })
        );
    }
  }, [detectionInProgress]);

  useEffect(() => {
    if (startRecording && model) {
      let elapsedTime = 0;
      if (!detectionInProgress) {
        setDetectionInProgress(true);
      }
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 640,
            height: 480,
          },
        })
        .then((stream) => {
          // function clockTick() {
          //   if (elapsedTime > maxTime || !startRecording) {
          //     setProgress(0);
          //     setRecording(false);
          //   } else {
          //     const newProgress = Math.min((elapsedTime / maxTime) * 100, 100);
          //     setProgress(newProgress);
          //     elapsedTime += interval;
          //     setTimeout(clockTick, interval);
          //   }
          // }
          // clockTick();
        });
    }
    if (!startRecording) setDetectionInProgress(false);
    return () => {
      setDetectionInProgress(false);
      if (recorder) {
        setRecorder(null);
      }
      setProgress(0);
    };
  }, [startRecording, model]);

  return (
    <div className={classes.column}>
      {!isAlertActive && (
        <div className={classes.webcamrecorder}>
          <div className={classes.circularframe} id="webcam-container">
            <canvas
              className={classes.webcamOverlay}
              id="webcam-overlay"
            ></canvas>
            <Webcam
              audio={false}
              videoConstraints={{
                facingMode: "user",
              }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={classes.webcam}
              mirrored
            />
          </div>
          <div className={classes.progressbar}>
            <CircularProgressbar
              value={progress}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                pathColor: "rgba(201, 124, 229, 1)",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WebcamRecorder;
