import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomeScreen from "./HomeScreen/HomeScreen";
import Login from "./Login/Login";
import Selftest from "./Selftest/Selftest";
import Selftest1 from "./Selftest1/Selftest1";
import Register from "./Registration/Register";
import Vitals from "./ReportVitals/ReportVitals";
import Reports from "./Reports/Reports";
import RegisterUser from "./RegisterUser/RegisterUser";
import { useEffect, useState } from "react";
import Reports1 from "./Reports/Reports1";
import DoshaVitals from "./ReportVitalsDosha/ReportVitals.js";
import { useNavigate } from "react-router-dom";
import ShortPDFVitals from "../src/ShortReportPDF/ReportVitals";
import HrvBodyCardVitals from "./HrvBodyCardVitals/ReportVitals";
import HrvBodyCardHemoVitals from "./HrvBodyCardHemoVitals/ReportVitals";
import userdata from "./typesofusers.json";
import Selftest2 from "./Selftest2/Selftest2";
import SelftestPalm from "./SelftestPalm/SelftestPalm.js";
import ReportPalm from "./ReportVitalsPalm/ReportVitals.js";
import ReportFacePalm from "./ReportVitalsFacePalm/ReportVitals.js";
import JamaicaVitals from "./JamaicaVitals/JamaicaVitals.js";

import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import SelftestFacePalm from "./SelftestFacePalm/SelftestFacePalm.js";
import Report from "./ReportVitalsFacePalm/NewReportVitals.js";
import MyPDF from "./ReportVitalsFacePalm/PDFView.js";
import MyPDFTest from "./ReportVitals/PDFView.js";
import MyPDFPalm from "./ReportVitalsPalm/PDFView.js";
import MyPDFDosha from "./ReportVitalsDosha/PDFView.js";

function App() {
  const [did, setdid] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [loginInvoked, setLoginInvoked] = useState(false);
  const [tokenGen, setTokenGen] = useState(false);

  console.log("token gen", tokenGen);
  async function getuser(deviceId) {
    console.log(
      "getUser is called,\n userExistence:",
      userExists,
      "\ndevice Id:",
      deviceId
    );
    if (!deviceId || deviceId === "") return;
    const path =
      userdata.form2user &&
      userdata.form2user.includes(localStorage.getItem("accno"))
        ? "apollouser1"
        : userdata.form3user &&
          userdata.form3user.includes(localStorage.getItem("accno"))
        ? "palmuser"
        : userdata.facePalmUser.includes(localStorage.getItem("accno"))
        ? "facepalmuser"
        : "apollouser";
    await axios
      .get(`https://iterve.aivot.ai/${path}/thisdid`, {
        params: { did: deviceId },
      })
      .then((res) => {
        console.log(res.data, res.status);
        if (res.status == 200) {
          console.log("user with device id found");
          localStorage.setItem("accno", res.data.accountNumber);
          setUserExists(true);
        } else {
          console.log("the device id does not corresponds to any user");
          setUserExists(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // Generate fingerprint
    Fingerprint2.get(async (components) => {
      const deviceId = await Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
      // if(loginInvoked)

      getuser(deviceId);
    });
  }, [did]);

  useEffect(() => {
    console.log("log in press caught through app js useEffect");
    // Generate fingerprint
    Fingerprint2.get(async (components) => {
      const deviceId = await Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
      // if(loginInvoked)
      getuser(deviceId);
    });
  }, [loginInvoked]);

  // useEffect (() => {
  //   console.log(did);
  //   if(did && localStorage.getItem('accno')) {
  //     const accno = localStorage.getItem('accno');
  //     // localStorage.removeItem('accno')

  //     axios.get("https://iterve.aivot.ai/apollouser", { params: { accno } })
  //     .then((res) => {
  //       console.log(res.data);
  //       console.log(accno)
  //       console.log(res.data.deviceid, res.data,res.status);
  //       console.log(res.data.deviceid, did);
  //       if(res.data.deviceid !== did){
  //         setUserExists(false)
  //       }
  //       else{
  //         setUserExists(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //   }
  //   setUserExists(true);
  // },[]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            userExists ? (
              <Navigate
                to={
                  userdata.form2user.includes(localStorage.getItem("accno"))
                    ? "/reports1"
                    : userdata.facePalmUser.includes(
                        localStorage.getItem("accno")
                      )
                    ? "/reports1"
                    : userdata.form3user.includes(localStorage.getItem("accno"))
                    ? "/reports1"
                    : "/reports"
                }
              />
            ) : (
              <HomeScreen />
            )
          }
        />
        <Route
          path="/login"
          element={<Login setLoginInvoke={setLoginInvoked} />}
        />
        <Route path="/selftest/:token" element={<Selftest />} />
        <Route
          path="/selftest"
          element={
            userExists ? (
              <Selftest />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route
          path="/selftest1"
          element={
            userExists ? (
              <Selftest1 />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route
          path="/selftestpalm"
          element={
            userExists ? (
              <SelftestPalm />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route path="/selftest1/:token" element={<Selftest1 />} />
        <Route path="/register" element={<Register />} />
        <Route path="/vitals" element={<Vitals />} />
        <Route path="/doshavitals" element={<DoshaVitals />} />
        <Route path="/doshavitals/:pid/:cid" element={<DoshaVitals />} />
        <Route path="/vitals/:pid/:cid" element={<Vitals />} />
        <Route
          path="/registeruser"
          element={<RegisterUser setTokenGen={setTokenGen} />}
        />
        <Route path="/jamaicavitals" element={<JamaicaVitals />} />
        <Route path="/shortvitals" element={<ShortPDFVitals />} />
        <Route path="/hrvbodycardvitals" element={<HrvBodyCardVitals />} />
        <Route
          path="/hrvbodycardhemovitals"
          element={<HrvBodyCardHemoVitals />}
        />
        <Route path="/shortvitals/:pid/:cid" element={<ShortPDFVitals />} />
        <Route
          path="/hrvbodycardvitals/:pid/:cid"
          element={<HrvBodyCardVitals />}
        />
        <Route
          path="/hrvbodycardhemovitals/:pid/:cid"
          element={<Selftest2 />}
        />

        <Route
          path="/selftest2"
          element={
            userExists ? (
              <Selftest2 />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route path="/selftestFacePalm" element={<SelftestFacePalm />} />

        <Route path="/pdf" element={<MyPDF />} />

        <Route path="/pdftest" element={<MyPDFTest />} />

        <Route path="/pdfpalm" element={<MyPDFPalm />} />

        <Route path="/pdfdosha" element={<MyPDFDosha />} />

        <Route
          path="/reports"
          element={
            userExists ? (
              <Reports />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route
          path="/reports1"
          element={
            userExists ? (
              <Reports1 />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route
          path="/reportspalm"
          element={
            userExists ? (
              <ReportPalm />
            ) : (
              <Login setLoginInvoke={setLoginInvoked} />
            )
          }
        />
        <Route
          path="/reportsfacepalm"
          element={
            userExists ? <Report /> : <Login setLoginInvoke={setLoginInvoked} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
