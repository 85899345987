import React, { useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import MyDocument from "./myPDF";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Pdf() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const healthParameters = state.healthParameters;
  const formData = state.formData;
  const accno = state.accountNumber;
  const doshaParameters = state.doshaParameters;
  const mealrecommended = state.mealrecommended;
  const pageDataArray = state.pageDataArray;

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const generatePdf = async () => {
        const document = (
          <MyDocument
            healthParameters={healthParameters}
            formData={formData}
            accno={accno}
            doshaParameters={doshaParameters}
            mealrecommended={mealrecommended}
            pageDataArray={pageDataArray}
          />
        );
        console.log("doc oc", document);
        const blob = await pdf(document).toBlob();
        const url = URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.download = `${formData["name"]}_report.pdf`;
        link.click();
        URL.revokeObjectURL(url);
      };

      generatePdf();
      navigate(-1);
    }
  }, [
    healthParameters,
    formData,
    accno,
    doshaParameters,
    mealrecommended,
    pageDataArray,
  ]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        margin: 0,
      }}
    >
      <p>Generating your report, please wait...</p>
    </div>
  );
}

export default Pdf;
