import React, { useState, useRef, useEffect } from "react";
import classes from "./YourStyles.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo 2.png";
import watermark from "../Images/watermark.png";
import HiimsLogo from "../Images/HIIMSLogo.png";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink, View, pdf } from "@react-pdf/renderer";
import MyPDF from "./myPDF";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wpimage from "../Images/whatsapp.png";
import mailimage from "../Images/mail.png";
import appleimage from "../Images/apple.png";
import androidimage from "../Images/android.png";
import webimage from "../Images/www.png";
import copyimage from "../Images/copy.png";
import copydone from "../Images/done.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tickimage from "../Images/tick.png";
import cross from "../Images/remove.png";
import gamil from "../Images/gmail.png";
import yahoo from "../Images/yahoo.png";
import outlook from "../Images/outlook.png";
import userdata from "../typesofusers.json";
import Hiims_icon from "../Images/Hiims_icon.png";
import BJP_icon from "../Images/BJP_icon.png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import MyDocument from "./myPDF";

const ReportsPalm = () => {
  const mypdfRef = useRef(null);
  const [isDownloaded, setDownloaded] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [healthParameters, sethealthParameters] = useState();
  const [doshaParameters, setdoshaParameters] = useState([]);
  const [doshacombinations, setdoshacombinations] = useState();
  const [formData, setFormData] = useState({});
  const [vitals, setvitals] = useState();
  const [childid, setchildid] = useState("");
  const [parentid, setparentid] = useState("");
  const [sharepopup, setsharepopup] = useState(false);
  const [whatsappopt, setwhatsappopt] = useState(false);
  const [mailopt, setmailopt] = useState(false);
  const [copycompleted, setcopycompleted] = useState(false);
  const [accountNumber, setaccountNumber] = useState("");
  const [enduserid, setenduserid] = useState("");
  const [ecgPlot, setEcgPlot] = useState();
  const [ecgImage, setEcgImage] = useState();
  const [fullBase64String, setFullBase64String] = useState();
  const [mealrecommended, setmealrecommended] = useState();
  console.log("vitals page rendered");

  const location = useLocation();
  const navigate = useNavigate();

  const vitals1 = [
    { vital: "Heart Rate", value: "72", unit: "bpm", reference: "60-100" },
  ];
  const vitals3 = [
    { vital: "Temperature", value: "98.6", unit: "°F", reference: "97-99" },
  ];
  const vitals2 = [
    {
      vital: "Blood Pressure",
      value: "120/80",
      unit: "mmHg",
      reference: "90-120/60-80",
    },
  ];

  useEffect(() => {
    console.log(location.state);
    console.log(parentid, childid);

    if (location.state) {
      setvitals(location.state);
      console.log(location.state);
      setenduserid(location.state.id);
      console.log(localStorage.getItem("accno"));
      var accno = localStorage.getItem("accno");
      if (!accno) {
        accno = "";
      }
      setaccountNumber(accno);
      if (accno !== "" && accno !== "enduser") {
        console.log("accno exists", accno);
        var findid = "";
        console.log("before report vitals", accno);
        const dbcollection =
          userdata.form3user &&
          userdata.form3user.includes(localStorage.getItem("accno"))
            ? "palmuser"
            : userdata.facePalmUser.includes(localStorage.getItem("accno"))
            ? "facepalmuser"
            : "apollouser";
        console.log("user id geting fetched from", dbcollection, "collection");
        axios
          .get(`https://iterve.aivot.ai/${dbcollection}/getuserid`, {
            params: { accno },
          })
          .then((res) => {
            findid = res.data;
            console.log(res.data);
            setparentid(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
        setchildid(location.state._id);
      } else {
        setparentid("enduser");
        setchildid(location.state.id);
      }
      // fetch user Id as object id and set it
    } else {
      console.log("else");
      const objectId = location.pathname.split("/")[2].slice(4);
      const vitalsDataId = location.pathname.split("/")[3].slice(4);
      setparentid(objectId);
      setchildid(vitalsDataId);
      console.log(parentid, childid);
      if (!objectId || !vitalsDataId) navigate("/reports");
      const dbcollection = objectId === "enduser" ? "enduser" : "apollouser";
      fetch(
        `https://iterve.aivot.ai/${dbcollection}/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setvitals(objectId === "enduser" ? res : res.vitalsData);
          setaccountNumber(res.accountNumber ? res.accountNumber : "");
        });
    }
  }, [location.state]);

  useEffect(() => {
    // setmealrecommended(dietRecommendations[doshacombinations.toLowerCase()]);
    const fetchDietRecommendation = async () =>
      await axios
        .get("https://iterve.aivot.ai/facepalmuser/dietRecommendation", {
          params: { category: doshacombinations },
        })
        .then((res) => {
          console.log(res);
          setmealrecommended(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    doshacombinations && fetchDietRecommendation();
  }, [doshacombinations]);

  useEffect(() => {
    console.log(doshacombinations);
    console.log(mealrecommended);
  }, [mealrecommended]);

  useEffect(() => {
    console.log(vitals);

    if (vitals) {
      setdoshacombinations(vitals["doshaCombination"]);

      const malesBelow18 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "70 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "60 - 75",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "14 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 54",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const malesBetween18And45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "12 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 52",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const malesAbove45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 52",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesBelow18 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "70 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "60 - 75",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesBetween18And45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesAbove45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "45 - 60",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "20 - 30",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "20 - 30",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "For fasting 70-100, For RBS > 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const palmbuser = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "Beats per min",
          referenceRange: "55 - 100",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Breathing Rate",
          value: vitals["breathing_rate"],
          unit: "BPM",
          referenceRange: "12 – 20",
        },
        {
          vital: "Pulse Rate Quotient",
          value: vitals["pulse_rate_quotient"],
          unit: "Beats per min",
          referenceRange: "4",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "Kg/m^2",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50% - 60%",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "M < 24, F < 31",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "1 - 12",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "M 8-25, F 20-35",
        },
        {
          vital: "Cardiac Output",
          value: vitals["cardiac_output"],
          unit: "L/min",
          referenceRange: "5-6",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L(min.m^2) ",
          referenceRange: "2.5-4",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "milli litre",
          referenceRange: "50-100",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "0 – 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "< 90",
        },
        {
          vital: "Mean Arterial Pressure",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "40 – 60",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "< 100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: "40-60",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "< 150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "< 200",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "5 - 40",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "million cell/mcl",
          referenceRange: "4.5 – 5.5",
        },
        {
          vital: "Mean Corpuscular Value*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "83.0 – 101.0",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg/cells",
          referenceRange: "27.0 - 32.0",
        },
        {
          vital: "Blood Volume",
          value: vitals["bloodVolume"],
          unit: "mL",
          referenceRange: "5000",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13.0 – 17.0",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40.0 - 50.0",
        },
        {
          vital: "Random Blood Sugar",
          value: localStorage.getItem("truerbs"),
          unit: "mg/dl",
          referenceRange: "70 – 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "< 5.7%",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 100",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15 - 73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "FLI",
          referenceRange: "<30",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dL",
          referenceRange: "3.5-7.2",
        },

        // {
        //   vital: "T3 (Triiodothyronine)",
        //   value: "",
        //   unit: "ng/dl",
        //   referenceRange: "80-200",
        // },
        // {
        //   vital: "T4 (Thyroxine)",
        //   value: "",
        //   unit: "μg/dL",
        //   referenceRange: "4.8 – 12.7",
        // },
        // {
        //   vital: "TSH (Thyroid Stimulating Hormone)",
        //   value: "",
        //   unit: "μIU/mL",
        //   referenceRange: "0.54 – 5.30",
        // },

        // {
        //   vital: "Allowable Blood Loss",
        //   value: vitals["allowable_blood_loss"],
        //   unit: "milli litres",
        //   referenceRange: "< 14",
        // },
      ];

      setEcgPlot(vitals["ecgplot"]);
      if (
        localStorage.getItem("accno") === "Jintur1" ||
        localStorage.getItem("accno") === "Jintur2"
      ) {
        sethealthParameters(palmbuser);
      } else {
        if (vitals["gender"] === "Male" && parseInt(vitals["age"]) < 18) {
          sethealthParameters(malesBelow18);
        } else if (
          vitals["gender"] === "Male" &&
          parseInt(vitals["age"]) >= 18 &&
          parseInt(vitals["age"]) <= 45
        ) {
          sethealthParameters(malesBetween18And45);
        } else if (
          vitals["gender"] === "Male" &&
          parseInt(vitals["age"]) > 45
        ) {
          sethealthParameters(malesAbove45);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) < 18
        ) {
          sethealthParameters(femalesBelow18);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) >= 18 &&
          parseInt(vitals["age"]) <= 45
        ) {
          sethealthParameters(femalesBetween18And45);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) > 45
        ) {
          sethealthParameters(femalesAbove45);
        }
      }

      setdoshaParameters([
        {
          vital: "Vata",
          percentage: Math.round(parseFloat(vitals["vata"])),
          balance: vitals["vatabalance"],
        },
        {
          vital: "Pitta",
          percentage: Math.round(parseFloat(vitals["pitta"])),
          balance: vitals["pittabalance"],
        },
        {
          vital: "Kapha",
          percentage: Math.round(parseFloat(vitals["kapha"])),
          balance: vitals["kaphabalance"],
        },
      ]);

      console.log(vitals);
      setFormData({
        name: vitals["name"],
        phoneNumber: vitals["phoneNumber"],
        email: vitals["email"],
        subjectid: vitals["subjectid"],
        gender: vitals["gender"],
        age: vitals["age"],
        height: vitals["height"],
        weight: vitals["weight"],
      });
    }
  }, [vitals]);

  const tableHead = ["Name", "Subject ID", "Gender", "Age", "Height", "Weight"];

  useEffect(() => {
    if (ecgPlot) {
      setFullBase64String(`data:image/png;base64,${ecgPlot}`);
    }
  }, [ecgPlot]);

  useEffect(() => {
    console.log("ecgImage", ecgImage);
  }, [ecgImage]);

  const [recipientEmail, setRecipientEmail] = useState("aniroycr537@gmail.com");
  const [file, setFile] = useState(null);

  const handleMailButtonClick = async (prov) => {
    try {
      // Generate PDF blob

      // console.log(parentid,childid);

      if (prov === "gmail") {
        var link = `https://mail.google.com/mail/u/0/?fs=1&to&su=check+out+the+health+vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}&tf=cm`;
      } else if (prov === "yahoo") {
        var link = `https://compose.mail.yahoo.com/?subject=check+out+the+health+vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      } else {
        var link = `https://outlook.office.com/mail/deeplink/compose/?subject=check%20out%20the%20health%20vitals&body=https://iterve.aivot.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      }
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleMailButtonClick = async () => {
  //   // Fetching the PDF file
  //   const blob = await pdf(<MyPDF healthParameters={healthParameters} formData={formData} />).toBlob();
  //   console.log('blob', blob);
  //   // Converting the PDF file to base64
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //       const base64data = reader.result.split(',')[1];

  //       // Generate mailto link
  //       const recipient = 'recipient@gmail.com';
  //       const subject = 'Your Subject';
  //       const body = 'Please find the attached PDF.';
  //       const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(base64data)}`;

  //       // Open mail app
  //       console.log(mailtoUrl);
  //       window.open(mailtoUrl, '_blank');
  //   };
  // };

  const copyToClipboard = async (text) => {
    console.log(text);
    await navigator.clipboard
      .writeText(text)
      .then(() => {
        setcopycompleted(true);
        setTimeout(() => {
          setcopycompleted(false);
        }, 3000);
        toast.success("Text copied to clipboard!", {
          autoClose: 1500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsappClick = async (s) => {
    //whatsapp share

    const sharelink = `https://${s}.whatsapp.com/send?text=https://iterve.aivot.ai/vitals/pid=${parentid}/cid=${childid}`;
    // const a = document.createElement('a');
    // a.href=sharelink;
    // a.click();
    window.open(sharelink, "_blank");
  };

  function formatNumber(num) {
    // Check if the number is an integer
    if (Number.isInteger(num)) {
      return num;
    } else {
      // Format the number to 2 decimal places
      return num.toFixed(2);
    }
  }

  const vitalsTableData = vitals
    ? [
        {
          name: "Vata",
          valueX: Math.round(parseFloat(vitals["vata"])),
          valueY: vitals["vatabalance"],
        },
        {
          name: "Pitta",
          valueX: Math.round(parseFloat(vitals["pitta"])),
          valueY: vitals["pittabalance"],
        },
        {
          name: "Kapha",
          valueX: Math.round(parseFloat(vitals["kapha"])),
          valueY: vitals["kaphabalance"],
        },
      ]
    : [];
  let pageDataArray = [];
  if (vitals) {
    pageDataArray = [
      {
        page: "page1",
        data: [
          {
            label: "IBI",
            value: vitals.ibi,
            unit: "ms",
            referenceRange: "600 – 1200",
          },
          {
            label: "Heart Rate",
            value: vitals["heart_rate"],
            unit: "bpm",
            referenceRange: "70 - 100",
          },
          {
            label: "RMSSD",
            value: vitals.rmssd,
            unit: "milli sec",
            referenceRange: "80 – 200",
          },
          {
            label: "SDNN",
            value: vitals.sdnn,
            unit: "milli sec",
            referenceRange: "70 – 150",
          },
          {
            label: "SD1",
            value: vitals.sd1,
            unit: "milli sec",
            referenceRange: "60 – 140",
          },
          {
            label: "SD2",
            value: vitals.sd2,
            unit: "milli sec",
            referenceRange: "70 – 180",
          },
          {
            label: "Stress Index*",
            value: vitals.stress_index,
            unit: "%",
            referenceRange: "<27",
          },
          {
            label: "SpO2",
            value: vitals.oxygen_saturation,
            unit: "%",
            referenceRange: "95 – 100",
          },
          {
            label: "SBP",
            value: vitals.sbp,
            unit: "mmHg",
            referenceRange: "90 – 120",
          },
          {
            label: "DBP",
            value: vitals.dbp,
            unit: "mmHg",
            referenceRange: "60 - 80",
          },
        ],
        paragh: `These parameters relate to heartbeat deviations and reflect autonomic nervous system activity:
• SDNN: Measures overall heartbeat variability (standard deviation of NN intervals).
• RMSSD: Reflects short-term heartbeat changes (root mean square of successive differences).
• SD1: Represents short-term heartbeat variability (Poincaré plot, perpendicular to identity line).
• SD2: Indicates long-term heartbeat variability (Poincaré plot, along the identity line).
• IBI: Time interval between consecutive heartbeats (in milliseconds).
• SBP(Systolic Blood Pressure): The pressure in arteries during heart contractions, reflecting cardiovascular force.
• DBP(Diastolic Blood Pressure): The pressure in arteries between heart beats, reflecting vascular resistance.
• Heart Rate (HR): The number of heartbeats per minute, reflecting the cardiac rhythm and autonomic balance.
These metrics assess cardiac health and autonomic balance
`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },

      {
        page: "page2",
        data: [
          {
            label: "Body Mass Index",
            value: vitals.bmi,
            unit: "kg/m²",
            referenceRange: "18.5 – 24.9",
          },
          {
            label: "Body Water",
            value: vitals.body_water,
            unit: "%",
            referenceRange: "60 - 75",
          },
          {
            label: "Body Fat",
            value: vitals.body_fat,
            unit: "%",
            referenceRange: "10 - 20",
          },
          {
            label: "Visceral Fat",
            value: vitals.visceral_fat,
            unit: "%",
            referenceRange: "5 - 15",
          },
          {
            label: "Subcutaneous Fat",
            value: vitals.subcutaneous_fat,
            unit: "%",
            referenceRange: "10 - 20",
          },
        ],
        paragh: `These are body component parameters used to assess physical health and composition:
• Body Mass Index (BMI): A measure of body weight relative to height, used to assess healthy weight ranges.
• Body Water: The percentage of total body weight made up of water, indicating hydration status.
• Body Fat: The proportion of fat in the body, reflecting overall fat composition.
• Visceral Fat: Fat stored around internal organs, linked to metabolic health risks.
• Subcutaneous Fat: Fat stored beneath the skin, influencing body shape and insulation.
These parameters help evaluate overall health, fitness, and body composition.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page3",
        data: [],
        paragh: `These are cardiac health parameters used to evaluate heart function
ECG (Electrocardiogram): A test that measures the electrical activity of the heart to detect
abnormalities in rhythm, rate, and conduction.

These metrics assess heart performance and circulatory health.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page4",
        data: [
          {
            label: "Cardiac Index",
            value: vitals.cardiac_index,
            unit: "L/min/m²",
            referenceRange: "2.5 – 4.0",
          },
          {
            label: "Cardiac Output",
            value: vitals.cardiac_output,
            unit: "L/min",
            referenceRange: "5-6",
          },
          {
            label: "Stroke Volume",
            value: vitals.stroke_volume,
            unit: "ml",
            referenceRange: "60 - 100",
          },
          {
            label: "Pulse Pressure",
            value: vitals["pulse_pressure"],
            unit: "mmHg",
            referenceRange: "30 - 50",
          },
          {
            label: "MAP",
            value: vitals["mean_atrial_pressure"],
            unit: "mmHg",
            referenceRange: "70 - 100",
          },
        ],
        paragh: `These are cardiac health parameters used to evaluate heart function:
• Cardiac Output (CO): The total volume of blood the heart pumps per minute, reflecting overall heart efficiency.
• Cardiac Index (CI): Cardiac output adjusted for body surface area, providing a personalized measure of heart function.
• Stroke Volume (SV): The amount of blood ejected by the heart in a single heartbeat, indicating heart strength.
• Pulse Pressure (PP): The difference between systolic and diastolic blood pressure, indicating the force the heart generates during each
contraction.
• Mean Arterial Pressure (MAP): The average blood pressure in the arteries during a single cardiac cycle, essential for assessing organic
perfusion.
These metrics assess heart performance and circulatory health.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page5",
        data: [
          {
            label: "Random Blood Sugar*",
            value: vitals.random_blood_sugar,
            unit: "mg/dl",
            referenceRange: "For fasting 70-100, For RBS > 140",
          },
          {
            label: "Mean Plasma Glucose",
            value: vitals.mean_plasma_glucose,
            unit: "mg/dl",
            referenceRange: "70 - 140",
          },
          {
            label: "HbA1c",
            value: vitals.hba1c,
            unit: "%",
            referenceRange: "4.0 - 5.6",
          },
          {
            label: "Red Blood Cells*",
            value: vitals.rbc_count,
            unit: "mil/uL",
            referenceRange: "4.5 - 5.9",
          },
          {
            label: "MCH*",
            value: vitals.mean_corpuscular_hemoglobin,
            unit: "pg",
            referenceRange: "27 - 32",
          },
          {
            label: "MCV*",
            value: vitals.mean_corpuscular_volume,
            unit: "fl",
            referenceRange: "80 – 100",
          },
          {
            label: "Hemoglobin",
            value: vitals.hemoglobin,
            unit: "g/dl",
            referenceRange: "12 - 15",
          },
        ],
        paragh: `These are hematology parameters used to assess blood health and glucose regulation:
• Random Blood Sugar (RBS): Glucose level at any given time, indicating blood sugar status.
• Mean Plasma Glucose (MPG): Average blood glucose level over time, derived from HbA1c.
• HbA1c: Reflects average blood sugar levels over the past 2-3 months, crucial for diabetes management.
• Hematocrit: Percentage of red blood cells in total blood volume, indicating oxygen-carrying capacity.
• Red Blood Cells (RBC): Number of red blood cells in the blood, essential for oxygen transport.
• MCH (Mean Corpuscular Hemoglobin): Average amount of hemoglobin in a single red blood cell.
• MCV (Mean Corpuscular Volume): Average size of red blood cells, used to diagnose anemia types.
• Allowable Blood Loss: Estimated safe blood loss during medical procedures.
• Hemoglobin: Protein in red blood cells that carries oxygen throughout the body.
These parameters provide insights into blood health, oxygen transport, and glucose control.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page6",
        data: [
          {
            label: "HDL*",
            value: vitals.hdl,
            unit: "mg/dl",
            referenceRange: ">40",
          },
          {
            label: "LDL*",
            value: vitals.ldl,
            unit: "mg/dl",
            referenceRange: "<100",
          },
          {
            label: "VLDL*",
            value: vitals.vldl,
            unit: "mg/dl",
            referenceRange: "<30",
          },
          {
            label: "Triglyceride*",
            value: vitals.triglycerides,
            unit: "mg/dl",
            referenceRange: "<150",
          },
          {
            label: "Total Cholesterol*",
            value: vitals.total_cholestrol,
            unit: "mg/dl",
            referenceRange: "<200",
          },
        ],
        paragh: `These are lipid profile parameters used to assess cardiovascular health:
• HDL (High-Density Lipoprotein): 'Good' cholesterol that helps remove excess cholesterol from the bloodstream.
• LDL (Low-Density Lipoprotein): 'Bad' cholesterol that can build up in artery walls, increasing heart disease risk.
• VLDL (Very Low-Density Lipoprotein): Transports triglycerides in the blood; high levels can contribute to plaque buildup in arteries.
• Triglycerides: A type of fat in the blood, providing energy but linked to heart risk if elevated.
• Total Cholesterol: The sum of HDL, LDL, and triglycerides, giving an overall measure of blood cholesterol.
These metrics evaluate lipid levels and their impact on heart health.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page7",
        data: [
          {
            label: "SGPT*",
            value: vitals.SGPT,
            unit: "U/L",
            referenceRange: "21-72",
          },
          {
            label: "SGOT*",
            value: vitals.SGOT,
            unit: "U/L",
            referenceRange: "17-59",
          },
          {
            label: "GGPP*",
            value: vitals.GGPP,
            unit: "U/L",
            referenceRange: "15-73",
          },
          {
            label: "Fatty Liver Index*",
            value: vitals.fattyLiverIndex,
            unit: "-",
            referenceRange: "<40",
          },
        ],
        paragh: `These are liver function parameters used to assess liver health:
• SGPT (Serum Glutamic Pyruvic Transaminase): An enzyme indicating liver cell damage; elevated levels suggest liver inflammation or
injury.
• SGOT (Serum Glutamic Oxaloacetic Transaminase): An enzyme found in the liver and other organs; high levels may signal liver or heart
damage.
• GGPP (Gamma-Glutamyl Pyrophosphatase): An enzyme associated with bile duct and liver function; elevated levels can indicate bile duct
issues or liver disease.
• Fatty Liver: Accumulation of fat in liver cells, often linked to obesity, alcohol consumption, or metabolic disorders, which can impair liver
function.
These metrics help monitor liver health and detect potential liver disorders.`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
      {
        page: "page8",
        data: [
          {
            label: "Uric Acid*",
            value: vitals.uricAcid,
            unit: "mg/dl",
            referenceRange: "3.5-8.5",
          },
        ],
        paragh: `Uric Acid is a kidney function parameter used to evaluate kidney health and metabolic balance:
• Uric Acid: A waste product formed from the breakdown of purines, excreted through the kidneys.
This parameter is critical for diagnosing and managing kidney-related and metabolic conditions.
`,
        note: "These values are only indicative, cannot be used for clinical. The vitals marked as * (asterisk) are under evaluation for testing the models.",
      },
    ];
  }

  useEffect(() => {
    console.log(pageDataArray);
  }, [pageDataArray]);

  const handlePdfView = () => {
    navigate("/pdf", {
      state: {
        healthParameters,
        formData,
        accountNumber,
        fullBase64String,
        doshaParameters,
        mealrecommended,
        pageDataArray,
      },
    });
  };

  return (
    <div className={classes.container} id="content-to-pdf">
      {/* <img src={watermark} className={classes.watermark} /> */}
      <div className={classes.headingRow}>
        <div style={{ width: "15vw" }}>
          <img
            src={Hiims_icon}
            alt="Hiims Logo here"
            className={
              accountNumber
                ? accountNumber === "Jintur1" || accountNumber === "Jintur2"
                  ? classes.logo1
                  : classes.logo1VisOff
                : classes.logo1VisOff
            }
          />
        </div>
        {localStorage.getItem("accno") !== "Jintur1" &&
        localStorage.getItem("accno") !== "Jintur2" ? (
          <div className={classes.headingText}>Your Vitals</div>
        ) : (
          <div className={classes.headingText}>
            <div className={classes.headingTextUp}>
              मा.आमदार मेघना साकोरे बोर्डीकर
            </div>
            <div className={classes.headingTextDown}>
              भारतीय जनता पक्ष (भाजप)
            </div>
          </div>
        )}
        {localStorage.getItem("accno") === "Jintur1" ||
        localStorage.getItem("accno") === "Jintur2" ? (
          <div style={{ width: "15vw" }}>
            <img
              src={BJP_icon}
              alt="BJP Logo here"
              className={classes.logoBJP}
            />
          </div>
        ) : (
          <div style={{ width: "15vw" }}>
            <img
              src={aivotLogo}
              alt="Aivot Logo here"
              className={classes.logo}
            />
          </div>
        )}
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Name:</div>

          <div>{formData.name}</div>
        </div>

        <div
          className={classes.email}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Email:</div>
          <div>{formData.email}</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>PhoneNumber</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.phoneNumber} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectid} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div>

      <div className={classes.vitalRows}>
        {vitals &&
          pageDataArray.map((page, pageIndex) => (
            <div className={classes.vitalsCol} key={pageIndex}>
              {/* <img src={aivotLogo} className={classes.watermark} /> */}
              <table className={classes.table}>
                {pageIndex !== 2 && (
                  <thead className={classes.vital}>
                    <tr>
                      <th>Label</th>
                      <th>Value</th>
                      <th>Unit</th>
                      <th>Reference Range</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {page.data.map((item, index) => (
                    <tr key={index} className={classes.row}>
                      <td>{item.label}</td>
                      <td style={{ textAlign: "center" }}>{item.value}</td>
                      <td style={{ textAlign: "center" }}>{item.unit}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.referenceRange}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {pageIndex === 2 && (
                <img src={fullBase64String} className={classes.ecgPlot} />
              )}
              <p className={classes.pageparagh}>
                {page.paragh.split("\n").map((line, index) => {
                  // Check if the line contains a colon, and make everything before the colon bold
                  const formattedLine = line.replace(
                    /(.*?:)/,
                    "<strong>$1</strong>"
                  );
                  // Add a <br /> after each line to preserve line breaks
                  return (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: formattedLine + "<br />",
                      }}
                    />
                  );
                })}
              </p>

              <p className={classes.note}>
                <b>Note:</b> {page.note}
              </p>
              <div
                className={classes.pageNo}
                style={{
                  textAlign: "center",
                  color: "rgba(0, 94, 146, 1)",
                  fontWeight: "bold",
                  fontSize: "1.5vw",
                  marginBottom: "4vw",
                }}
              >
                ●● {pageIndex + 1} ●●
              </div>
            </div>
          ))}
      </div>

      <div className={classes.tableheading}>Ayurvedic Tridoshas</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Dosha</th>
              <th>Prakruti</th>
              <th>Vikruti</th>
            </tr>
          </thead>
          <tbody>
            {vitalsTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ textAlign: "left", paddingLeft: 40 }}>
                  {row.name}
                </td>
                <td>{row.valueX}</td>
                <td>{row.valueY}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className={classes.pageparagh}>
        {`
    Ayurvedic Tridoshas represent the three fundamental energies that govern physical and mental processes in the body.
    
    Doshas: The dynamic energies—Vata, Pitta, and Kapha—that regulate biological functions.
    
    Prakruti: The unique, natural constitution of an individual, determined at birth.
    
    Vikruti: The current state of imbalance in the Doshas, influenced by lifestyle, environment, and habits.
    
    Dosha Analysis:
    
    Vata (33% - Increased): Governs movement, nervous system, and creativity. Imbalance can cause anxiety, restlessness, or dryness.
    
    Pitta (45% - Decreased): Governs metabolism, digestion, and transformation. Imbalance may lead to low energy, poor digestion, or irritability.
    
    Kapha (22% - Balanced): Governs stability, immunity, and structure. Balance provides calmness, strength, and resilience.
    
    This analysis blends Ayurveda with personalized insights to restore balance and harmony in the body and mind.
  `
          .split("\n")
          .map((line, index) => {
            // Bold the labels before the colons
            const formattedLine = line.replace(/(.*?:)/, "<strong>$1</strong>");
            // Add a <br /> after each line to preserve the line breaks
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: formattedLine + "<br />" }}
              />
            );
          })}
      </div>
      <div className={classes.noteSec}>
        <b>Note:</b> These values are only indicative, cannot be used for
        clinical. The vitals marked as * (asterisk) are under evaluation for
        testing the models.
      </div>
      <div
        className={classes.pageNo}
        style={{
          textAlign: "center",
          color: "rgba(0, 94, 146, 1)",
          fontWeight: "bold",
          fontSize: "1.5vw",
          marginBottom: "4vw",
        }}
      >
        ●● Page 9 ●●
      </div>

      <div className={classes.tableheading}>Food Recommendations</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Food Type</th>
              <th>Eat</th>
              <th>Avoid</th>
              <th>Occasional</th>
            </tr>
          </thead>
          <tbody>
            {mealrecommended?.Eat &&
              Object.keys(mealrecommended.Eat).map((foodType, index) => (
                <tr key={index}>
                  <td>{foodType}</td>
                  <td>{mealrecommended.Eat[foodType]?.join(", ") || ""}</td>
                  <td>{mealrecommended.Avoid?.[foodType]?.join(", ") || ""}</td>
                  <td>
                    {mealrecommended.Occasionally?.[foodType]?.join(", ") || ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <div className={classes.noteSec}>
        <div>
          <b>Note:</b>Do not consume any solid food post 7 PM
        </div>
      </div>
      <div
        className={classes.pageNo}
        style={{
          textAlign: "center",
          color: "rgba(0, 94, 146, 1)",
          fontWeight: "bold",
          fontSize: "1.5vw",
          marginBottom: "4vw",
        }}
      >
        ●● Page 10 ●●
      </div>

      <div className={classes.allbuttons}>
        <button
          className={classes.downloadBtn}
          onClick={() => {
            navigate("/reports1");
          }}
        >
          Home
        </button>

        <div onClick={handlePdfView} className={classes.downloadBtn}>
          Download
        </div>
      </div>
    </div>
  );
};

export default ReportsPalm;
