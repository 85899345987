import React, { useEffect } from "react";
import aivotLogo from "../Images/aivotLogo.png";
import watermark from "../Images/watermark.png";
import HiimsLogo from "../Images/HIIMSLogo.png";
import Hiims_icon from "../Images/Hiims_icon.png";
import BJP_icon from "../Images/BJP_icon.png";
import iterveLogo from "../Images/iterveLogo.png";

import NotoSansDevanagari from "../Fonts/NotoSansDevanagari-VariableFont_wdth,wght.ttf";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { blue } from "@mui/material/colors";

// Define your styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
    padding: 20,
  },
  infoSec: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    // justifyContent: "space-around",
  },
  infoLabel: {
    fontSize: "1.5vw",
    padding: "0 2vw",
  },
  name: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    fontSize: "1.8vw",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  email: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    fontSize: "1.8vw",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  phoneNumber: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  subject: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  gender: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  age: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  height: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  weight: {
    width: "16.67%",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    borderTop: "0.1vw solid rgba(0, 94, 146, 1)",
    borderLeft: "0.1vw solid rgba(0, 94, 146, 1)",
    borderBottom: "0.1vw solid rgba(0, 94, 146, 1)",
    borderRight: "0.1vw solid rgba(0, 94, 146, 1)",
    color: "rgba(0, 94, 146, 1)",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
    border: "0.1vw solid rgba(0, 94, 146, 1)",
    borderTop: "none",
    // paddingBottom: 5,
  },
  tableCell: {
    width: "25%",
    borderRight: "1px solid rgba(0, 94, 146, 1)",
    padding: 10,
    fontSize: "2vw",
    textAlign: "center",
    color: "rgba(0, 94, 146, 1)",
  },
  tableCell1: {
    width: "25%",
    padding: 10,
    fontSize: "2vw",
    textAlign: "center",
    color: "rgba(0, 94, 146, 1)",
  },
  tableCellLeft: {
    width: "25%",
    borderRight: "1px solid rgba(0, 94, 146, 1)",
    padding: 10,
    fontSize: "2vw",
    textAlign: "left",
    color: "rgba(0, 94, 146, 1)",
  },
  tableCell2: {
    width: "25%",
    borderRight: "1px solid rgba(0, 94, 146, 1)",
    padding: 10,
    fontSize: "1.4vw",
    textAlign: "left",
    color: "rgba(0, 94, 146, 1)",
  },
  paragraph: {
    marginTop: 20,
    fontSize: 6,
    color: "black",
  },
  note: {
    marginTop: 10,
    fontSize: 10,
    color: "gray",
    fontStyle: "italic",
    textAlign: "center",
  },
  pageNumber: {
    display: "flex",
    position: "absolute",
    width: "100%",
    bottom: 10,
    marginTop: 20,
    marginHorizontal: "auto",
    fontSize: 10,
    textAlign: "center",
    color: "rgba(0, 94, 146, 1)",
  },
  logo1: {
    height: "7vw",
    width: "7vw",
  },
  vitalsCol: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    // backgroundColor: "grey",
    gap: "0",
    marginTop: 5,
    // borderTop: "0.1vw solid black",
  },
  vital: {
    width: "25%",
    fontSize: "1.7vw",
    color: "#303030",
    textAlign: "center",
    // backgroundColor: "red",
  },
  value: {
    width: "25%",
    fontSize: "1.7vw",
    color: "#303030",
    textAlign: "center",
    // backgroundColor: "rgba(0, 94, 146, 1)",
  },
  units: {
    width: "25%",
    fontSize: "1.7vw",
    color: "#303030",
    textAlign: "center",
    // backgroundColor: "red",
  },
  range: {
    width: "25%",
    fontSize: "1.7vw",
    color: "#303030",
    textAlign: "center",
    // backgroundColor: "green",
  },
  columnHead: {
    display: "flex",
    fontSize: "2.5vw",
    color: "rgba(0, 94, 146, 1)",
    padding: "2vw",
    borderRight: "1.2px solid rgba(0, 94, 146, 1)",
    borderBottom: "1.2px solid rgba(0, 94, 146, 1)",
    borderTop: "1.2px solid rgba(0, 94, 146, 1)",
  },
  columnHead1: {
    display: "flex",
    fontSize: "2.5vw",
    color: "rgba(0, 94, 146, 1)",
    padding: "2vw",
    borderBottom: "1.2px solid rgba(0, 94, 146, 1)",
    borderTop: "1.2px solid rgba(0, 94, 146, 1)",
    borderRight: "1.2px solid rgba(0, 94, 146, 1)",
    borderLeft: "1.2px solid rgba(0, 94, 146, 1)",
  },
  headerDetails: {
    width: "100%",
  },
  headImage: {
    width: "40%",
    height: "10%",
    resizeMode: "contain",
    marginHorizontal: "auto  ",
  },
  ecgPlot: {
    width: "55vw",
    marginHorizontal: "auto",
  },
  paragraph: {
    fontSize: 10,
    lineHeight: 1.4, // Adjusted line height for tighter spacing
    color: "#333",
  },
  bold: {
    fontWeight: "bold",
  },
  watermark: {
    position: "absolute",
    display: "block",
    height: "200%",
    width: "280%",
    top: "-50%",
    left: "-80%",
    opacity: 0.3,
  },
  backgroundWatermark: {
    position: "absolute", // Position as background
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2, // Set transparency
  },
});

// Create your PDF component
const MyPDF = ({ healthParameters, formData, accno, pageDataArray }) => {
  Font.register({
    family: "NotoSansDevanagari",
    src: NotoSansDevanagari,
  });

  // Create the table row component
  const TableRow = ({ label, value, unit, referenceRange }) => (
    <View>
      <View style={styles.tableRow}>
        <Text style={styles.tableCellLeft}>{label}</Text>
        <Text style={styles.tableCell}>{value}</Text>
        <Text style={styles.tableCell}>{unit}</Text>
        <Text style={styles.tableCell1}>{referenceRange}</Text>
      </View>
    </View>
  );

  // Create the page component for the PDF
  const Page1 = ({ pageData }) => (
    <Page style={styles.page}>
      <Image src={watermark} style={styles.backgroundWatermark} fixed />
      <Image src={iterveLogo} style={styles.headImage} />
      <View style={styles.headerDetails}>
        <View style={styles.infoSec}>
          <View style={styles.name}>
            <Text style={styles.infoLabel}>Name:</Text>
            <Text>{formData.name}</Text>
          </View>
          <View style={styles.email}>
            <Text style={styles.infoLabel}>Email:</Text>
            <Text>{formData.email}</Text>
          </View>
        </View>
        <View style={styles.infoSec}>
          <View style={styles.phoneNumber}>
            <Text>PhoneNumber</Text>
          </View>
          <View style={styles.subject}>
            <Text>Subject ID</Text>
          </View>
          <View style={styles.gender}>
            <Text>Gender</Text>
          </View>
          <View style={styles.age}>
            <Text>Age</Text>
          </View>
          <View style={styles.height}>
            <Text>Height</Text>
          </View>
          <View style={styles.weight}>
            <Text>Weight</Text>
          </View>
        </View>
        <View style={styles.infoSec}>
          <View style={styles.phoneNumber}>
            <Text>{formData.phoneNumber}</Text>
          </View>
          <View style={styles.subject}>
            <Text>{formData.subjectid}</Text>
          </View>
          <View style={styles.gender}>
            <Text>{formData.gender}</Text>
          </View>
          <View style={styles.age}>
            <Text>{formData.age}</Text>
          </View>
          <View style={styles.height}>
            <Text>{formData.height}</Text>
          </View>
          <View style={styles.weight}>
            <Text>{formData.weight}</Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.vitalsCol}>
          <View style={styles.vital}>
            <Text style={styles.columnHead1}>Vitals</Text>
          </View>
          <View style={styles.value}>
            <Text style={styles.columnHead}>Values</Text>
          </View>
          <View style={styles.units}>
            <Text style={styles.columnHead}>Units</Text>
          </View>
          <View style={styles.range}>
            <Text style={styles.columnHead}>Reference</Text>
          </View>
        </View>
        {pageData.data.map((row, index) => (
          <TableRow
            key={index}
            label={row.label}
            value={row.value}
            unit={row.unit}
            referenceRange={row.referenceRange}
          />
        ))}
      </View>
      <View>
        {pageData.paragh.split("\n").map((line, index) => {
          const parts = line.split(/:(.*)/);
          return (
            <Text key={index} style={styles.paragraph}>
              {parts[0] && (
                <Text>
                  {parts[0]}
                  {parts[1] && parts[1] !== "" ? ":" : ""}
                </Text>
              )}
              {parts[1] && <Text>{parts[1]}</Text>}
              {"\n"}
            </Text>
          );
        })}
      </View>
      <Text style={styles.note}>Note: {pageData.note}</Text>
      <Text style={styles.pageNumber}>{pageData.page}</Text>
    </Page>
  );

  return (
    <Document>
      {pageDataArray.map((pageData, index) => (
        <Page1 key={index} pageData={pageData} />
      ))}
    </Document>
  );
};

export default MyPDF;
